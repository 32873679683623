<template>
    <div class="registration">
        <div class="container mx-auto p-4">
            <div class="max-w-md mx-auto">
                <div class="text-center">
                    <div class="w-full px-1 h-8">
                        <h2>
                            <text-fade-slider :texts="textFadeArray"/>
                        </h2>
                    </div>
                    <div class="w-full px-1">
                        <h2 v-text="name"></h2>
                    </div>
                </div>

                <div class="max-w-base mx-auto mt-4">
                    <!--Email-->
                    <input-field
                        class="mb-3"
                        v-model.trim.lowercase="email"
                        type="email"
                        autocomplete="email"
                        :is-valid="!errorHandler.has('email')"
                        :message="errorHandler.get('email')"
                        :label="$t('CORE.EMAIL')"/>

                    <!--Password-->
                    <input-field
                        class="mb-3"
                        v-model="password"
                        type="password"
                        :is-valid="!errorHandler.has('password')"
                        :message="errorHandler.get('password')"
                        :label="$t('CORE.PASSWORD')"/>

                    <!--OptInMarketing-->
                    <checkbox
                        class="w-full"
                        v-model="optIn"
                        :is-valid="!errorHandler.has('opt_in')"
                        :message="errorHandler.get('opt_in')"
                        :label="$t('CORE.OPT_IN_MARKETING')"/>

                    <div class="px-4">
                        <p class="text-xxs uppercase">{{ $t('REGISTRATION.ACCEPT_TERMS_COPY') }}
                            <router-link class="link" target="_BLANK" :to="{name:'terms.index'}">T&Cs</router-link>
                            & <router-link class="link" target="_BLANK" :to="{name:'privacy.index'}">Privacy Policy</router-link>.</p>
                    </div>
                </div>
            </div>
        </div>
        <!--Actions-->
        <div class="absolute py-2 bottom-0 w-screen">
            <div class="max-w-base mx-auto pt-4 text-center">
                <div class="w-full">
                    <btn
                        class="mb-2 button__btn--primary button__btn--fullWidth"
                        v-text="$t('REGISTRATION.GET_STARTED_CTA')"
                        :disabled="isFormProcessing"
                        @click="handleRegistrationRequest"/>
                </div>
                <div class="w-full h-6">
                    <router-link
                        :to="{name: 'auth.register.step-2'}"
                        class="link text-xs"
                        v-html="$t('REGISTRATION.BACK_CTA')">
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Btn from '@/components/Btn';
    import AuthService from '@/services/Auth/AuthService';
    import Checkbox from '@/components/forms/fields/Checkbox';
    import InputField from '@/components/forms/fields/InputField';
    import TextFadeSlider from '@/components/structure/TextFadeSlider';

    export default {
        name      : 'StepThree',
        metaInfo() {
            return {
                title: this.$t('META.REGISTRATION.TITLE'),
                meta : [
                    {
                        vmid   : 'robots',
                        name   : 'robots',
                        content: 'noindex,nofollow',
                    },
                ],
            };
        },
        data      : () => {
            return {
                isFormProcessing: false,
            };
        },
        created   : function () {
            if (!this.name) {
                return this.$router.push({name: 'auth.register.step-2'});
            }
        },
        computed  : {
            ...mapGetters({
                interfaceIso: 'Account/getInterfaceIso',
                learningIso : 'Register/getLearningIso',
                errorHandler: 'Register/getFormErrorHandler',
                name        : 'Register/getName',
            }),
            textFadeArray: function () {
                const intIso         = (this.interfaceIso || 'en').toLowerCase();
                const learnIdo       = (this.learningIso || 'en').toLowerCase();
                const textsToDisplay = [
                    this.$t('REGISTRATION.WELCOME_TEXT', intIso),
                    this.$t('REGISTRATION.WELCOME_TEXT', learnIdo),
                ];

                // Remove duplicates in instant a non en translation not found
                return [...new Set(textsToDisplay)];
            },
            canProceed   : function () {
                return (this.name || '').length > 0;
            },
            email        : {
                get() {
                    return this.$store.getters['Register/getEmail'];
                },
                set(value) {
                    this.$store.commit('Register/setEmail', value);
                },
            },
            password     : {
                get() {
                    return this.$store.getters['Register/getPassword'];
                },
                set(value) {
                    this.$store.commit('Register/setPassword', value);
                },
            },
            optIn        : {
                get() {
                    return this.$store.getters['Register/getOptIn'];
                },
                set(value) {
                    this.$store.commit('Register/setOptIn', value);
                },
            },
        },
        methods   : {
            /**
             * Handle making an API request to register the user with the data they have
             * provided.
             *
             * @returns {void}
             */
            handleRegistrationRequest() {
                const payload = {
                    name                  : this.name,
                    email                 : this.email,
                    password              : this.password,
                    opt_in                : this.optIn,
                    learning_language_iso : this.learningIso,
                    interface_language_iso: this.interfaceIso,
                    device_name           : 'web',
                };

                this.isFormProcessing = true;

                this.$http
                    .post(`${process.env.VUE_APP_API_URL}/api/users/registration`, payload)
                    .then(response => {
                        this.errorHandler.reset();
                        this.handleLoginRequest();
                    })
                    .catch(error => {
                        this.isFormProcessing = false;
                        this.errorHandler.setResponse(error.response.data);
                    });
            },

            /**
             * Handle authenticating the recently registered user.
             *
             * @returns {void}
             */
            handleLoginRequest: function () {
                this.isFormProcessing = true;

                AuthService.loginUser(this.email, this.password)
                    .then(() => {
                        // hard reload only seem to work at the moment as there must be a bug somewhere
                        window.location.href = '/app/courses';
                        this.errorHandler.reset();
                    })
                    .catch(error => {
                        this.isFormProcessing = false;
                        this.errorHandler.setResponse(error.response.data);
                    });
            },
        },
        components: {
            TextFadeSlider,
            InputField,
            Checkbox,
            Btn,
        },
    };
</script>
