import LoginUser from '@/services/Auth/LoginUser';
import LogoutUser from '@/services/Auth/LogoutUser';

export default class CourseService {
    /**
     * Handle the user login with the API. If successful a secure token is applied to the API
     * session that is included with the headers of each subsequent request.
     *
     * @param {String} email
     * @param {String} password
     * @param {String} device
     *
     * @returns {Promise<any>}
     */
    static loginUser = (email, password, device = 'web') => {
        return (new LoginUser()).handle(email, password, device);
    };

    /**
     * Handle the user logout with the API.
     *
     * @returns {Promise<any>}
     */
    static logoutUser = () => {
        return (new LogoutUser()).handle();
    };
}
