import store from '@/store/store';
import {http} from '@/services/AxiosClient';

export default class LoginUser {
    /**
     * Handle the user login with the API. If successful a secure token is applied to the API
     * session that is included with the headers of each subsequent request.
     *
     * @param {String} email
     * @param {String} password
     * @param {String} device
     *
     * @returns {Promise<any>}
     */
    handle(email, password, device = 'web') {
        const payload = {
            email      : email,
            password   : password,
            device_name: device,
        };

        return new Promise((resolve, reject) => {
            http.post('api/auth/login', payload)
                .then(response => {
                    store.commit('Account/resetState');


                    if (process.env.VUE_APP_ALLOW_INSECURE_ACCESS_TOKENS === 'true') {
                        store.commit('Account/updateAccessToken', response.data.data.access_token);
                    }

                    // Ensure access token is omitted
                    response.data.data.access_token = null;

                    store.commit('Register/resetState');
                    store.commit('Account/updateUuid', response.data.data.uuid);
                    store.dispatch('Account/setUserData', response.data.data)
                        .then(res => {
                            resolve(res);
                        });
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}
