import router from '@/router';
import store from '@/store/store';
import {http} from '@/services/AxiosClient';

export default class LoginUser {
    /**
     * Handle the user logout with the API.
     *
     * @returns {Promise<any>}
     */
    handle() {
        return new Promise((resolve, reject) => {
            http.post('api/auth/logout')
                .then(response => {
                    store.commit('Account/resetState');

                    resolve(response.data.data);
                })
                .catch(error => {
                    // User is likely already logged out or session expired
                    if (error.response && error.response.status === 401) {
                        setTimeout(function() {
                            store.commit('Account/resetState');
                            router.push({name: 'auth.login'});
                        }.bind(this), 0);
                    }

                    reject(error);
                });
        });
    }
}
