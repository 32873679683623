<template>
    <div class="registration">
        <div class="container mx-auto p-4">
            <div class="max-w-base mx-auto">
                <div class="text-center">
                    <h3 v-text="$t('REGISTRATION.YOUR_NAME_TITLE')"></h3>
                </div>
                <div class="max-w-base mx-auto pt-4">
                    <input-field
                        :label="$t('CORE.FIRST_NAME')"
                        v-model.trim="name"
                        autocomplete="given-name"
                        :is-valid="!errorHandler.has('name')"
                        :message="errorHandler.get('name')"
                    />
                </div>
            </div>
        </div>
        <!--Actions-->
        <div class="absolute py-2 bottom-0 w-screen">
            <div class="max-w-base mx-auto text-center">
                <div class="w-full">
                    <btn
                        class="mb-2 button__btn--primary button__btn--fullWidth"
                        v-text="$t('REGISTRATION.NEXT_CTA')"
                        :disabled="!canProceed"
                        @click="$router.push({name: 'auth.register.step-3'})"/>
                </div>
                <div class="w-full h-6">
                    <router-link
                        :to="{name: 'auth.register.step-1'}"
                        class="link text-xs"
                        v-html="$t('REGISTRATION.BACK_CTA')">
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Btn from '@/components/Btn';
    import InputField from '@/components/forms/fields/InputField';
    import TextFadeSlider from '@/components/structure/TextFadeSlider';

    export default {
        name      : 'StepTwo',
        metaInfo() {
            return {
                title: this.$t('META.REGISTRATION.TITLE'),
                meta : [
                    {
                        vmid   : 'robots',
                        name   : 'robots',
                        content: 'noindex,nofollow',
                    },
                ],
            };
        },
        created   : function () {
            if (!this.learningIso) {
                return this.$router.push({name: 'auth.register.step-1'});
            }
        },
        computed  : {
            ...mapGetters({
                interfaceIso: 'Account/getInterfaceIso',
                learningIso : 'Register/getLearningIso',
                errorHandler: 'Register/getFormErrorHandler',
            }),
            canProceed: function () {
                return (this.name || '').length > 0;
            },
            name      : {
                get() {
                    return this.$store.getters['Register/getName'];
                },
                set(value) {
                    this.$store.commit('Register/setName', value);
                },
            },
        },
        components: {
            TextFadeSlider,
            InputField,
            Btn,
        },
    };
</script>
