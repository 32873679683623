<template>
    <div class="registration">
        <div class="container mx-auto p-4">
            <div class="max-w-base mx-auto">
                <div class="mx-auto text-center">
                    <h3 class="">
                        {{ $t('HOMEPAGE.LANGUAGE_SELECTION.TITLE') }}
                    </h3>
                    <h5 class="">
                        {{ $t('HOMEPAGE.LANGUAGE_SELECTION.TITLE_SMALL_PRINT') }}
                    </h5>
                </div>
            </div>
            <learning-language-selector/>
        </div>

        <!--Actions-->
        <div class="absolute py-2 bottom-0 w-screen">
            <div class="max-w-base mx-auto text-center">
                <div class="w-full">
                    <btn
                        class="mb-2 button__btn--primary button__btn--fullWidth"
                        v-text="$t('REGISTRATION.NEXT_CTA')"
                        :disabled="!canProceed"
                        @click="$router.push({name: 'auth.register.step-2'})"/>
                </div>
                <div class="w-full h-6">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Btn from '@/components/Btn';
    import LearningLanguageSelector from '@/components/partials/Home/LearningLanguageSelector';

    export default {
        name      : 'StepOne',
        metaInfo  : [
            {
                name   : 'description',
                content: 'The home page of our example app.',
            },
        ],
        metaInfo() {
            return {
                title: this.$t('META.REGISTRATION.TITLE'),
                meta : [
                    {
                        vmid   : 'robots',
                        name   : 'robots',
                        content: 'NOODP,NOYDIR,INDEX,FOLLOW',
                    },
                    {
                        vmid   : 'description',
                        name   : 'description',
                        content: this.$t('META.REGISTRATION.DESCRIPTION'),
                    },
                ],
            };
        },
        computed  : {
            ...mapGetters({
                interfaceIso: 'Account/getInterfaceIso',
                learningIso : 'Register/getLearningIso',
                errorHandler: 'Register/getFormErrorHandler',
            }),
            canProceed: function () {
                return (this.learningIso || '').length > 0;
            },
        },
        components: {
            LearningLanguageSelector,
            Btn,
        },
    };
</script>
